import React from "react"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Socials from "./socials"

const BlackSocials = styled(Socials)`
  display: none;
  max-width: 280px;
  margin: 1em auto 0px;
  & a,
  & a:active {
    color: black;
  }
  & a:hover {
    color: #2196f3;
  }
  @media screen and (min-width: 600px) {
    display: flex;
    margin: 1em 0px 0px;
  }
`

const MobileSocials = styled(BlackSocials)`
  display: flex;
  @media screen and (min-width: 600px) {
    display: none;
  }
`

const AuthorAvatar = styled(Avatar)`
  width: 180px;
  height: 180px;
  margin: 0 auto;
  @media screen and (min-width: 600px) {
    margin: 0px 0px;
  }
`

const Title = styled(Typography)`
  text-align: center;

  @media screen and (min-width: 600px) {
    text-align: left;
  }
`

const ResponsiveBox = styled(Box)`
  flex-direction: column;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
`
const AboutMe = () => (
  <Box pb={3}>
    <Title variant="h4" paragraph>
      Sobre el autor
    </Title>
    <ResponsiveBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        flexWrap="wrap"
      >
        <AuthorAvatar alt="David Racero" src="/david_canillas_racero.jpg" />
        <Box mt={2}>
          <Typography variant="subtitle1" color="textSecondary">
            David Racero
          </Typography>
        </Box>
        <MobileSocials />
      </Box>
      <Box mt={2} mb={3} px={3} flex={1}>
        <Typography color="textSecondary" paragraph>
          David Racero es un programador Full Stack de Dapps y Smart Contracts
          en la red de Ethereum. Tras descubrir el proceso de minería Bitcoin en
          2013 y experimentar con diferentes blockchains, al descubrir el
          potencial de la tecnología decidió abandonar la universidad para
          enfocar toda su atención de manera autodidacta al ecosistema
          Blockchain, participando de manera activa en la red de Ethereum. En
          2018 se especializó en desarrollo de Smart Contracts con Consensys en
          Ethereum.{" "}
        </Typography>
        <Typography color="textSecondary" paragraph>
          Actualmente es desarrollador en una plataforma DeFi llamada {" "}
          <Link
            href="https://aave.com"
            rel="noopener nofollow"
          >
            Aave
          </Link>
          , donde puedes obtener intereses en depósitos y pedir préstamos en criptomonedas.
        </Typography>
        <BlackSocials />
      </Box>
    </ResponsiveBox>
  </Box>
)

export default AboutMe
