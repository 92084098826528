import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GithubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import Box from '@material-ui/core/Box';

const Icons = styled(Box) `
 & > * {
   margin: 0 0.4em;
 }
`

const Socials = (props) => (
  <Icons {...props} mt={5} display="flex" alignItems="center" justifyContent="space-around">
    <Typography variant="body1">Sígueme en</Typography>
    <a href="https://linkedin.com/in/davidcanillasracero" target="_blank" rel="nofollow noopener noreferrer">
      <LinkedInIcon fontSize="large" />
    </a>
    <a href="https://github.com/kartojal" target="blank" rel="nofollow noopener noreferrer">
      <GithubIcon fontSize="large" />
    </a>
    <a href="https://twitter.com/kartojal" target="blank" rel="nofollow noopener noreferrer">
      <TwitterIcon fontSize="large" />
    </a>
  </Icons>
)

export default Socials;
