/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, path, date, article }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            title
            description
            author
            url
            image
          }
        }
      }
    `
  )

  const { buildTime } = site
  const img = image || site.siteMetadata.image
  const imgFull = `${site.siteMetadata.url}${img}`
  const url = `${site.siteMetadata.url}${path || "/"}`
  const author = `${site.siteMetadata.author}` || "David Racero"

  const metaDescription = description || site.siteMetadata.description
  let schemaArticle
  let scripts = []
  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: url,
    headline: "Recursos y información sobre DeFi en Español",
    inLanguage: "es-ES",
    mainEntityOfPage: imgFull,
    description: metaDescription,
    name: title,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: "2020",
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Person",
      name: author,
    },
    datePublished: "2020-01-18T10:30:00+01:00",
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${url}favicons/android-chrome-512x512.png`,
    },
  }

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: "2020",
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${site.siteMetadata.url}/favicons/android-chrome-512x512.png`,
        },
      },
      datePublished: date,
      dateModified: date,
      description: description,
      headline: title,
      inLanguage: "es-ES",
      url: url,
      name: title,
      image: {
        "@type": "ImageObject",
        url: imgFull,
      },
      mainEntityOfPage: url,
    }
    scripts.push({
      type: "application/ld+json",
      innerHTML: JSON.stringify(schemaArticle),
    })
  } else {
    scripts.push({
      type: "application/ld+json",
      innerHTML: JSON.stringify(schemaOrgWebPage),
    })
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      script={scripts}
      meta={[
        {
          name: "theme-color",
          content: "#0734ff",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imgFull,
        },
        {
          property: `twitter:image`,
          content: imgFull,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: `@kartojal_eth`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
  image: null,
  path: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  img: PropTypes.string,
}

export default SEO
