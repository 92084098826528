import React from "react"
import styled from "styled-components"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Header from "./header"
import Footer from "./footer"

const BlogContainer = styled(Container)`
  color: #1a1a1a;
  margin-top: 40px;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      max-width: 680px;
      width: 100%;
      margin: 40px 40px 80px 40px;
    }
    `}
  & .MuiTypography-root {
    font-family: helvetica, sans-serif !important;
  }
`

const BlogLayout = ({ children }) => (
  <>
    <Header />
    <Box display="flex" justifyContent="center">
      <BlogContainer maxWidth="md">{children}</BlogContainer>
    </Box>
    <Footer />
  </>
)

export default BlogLayout
