import React from "react"
import styled from "styled-components"
import Link from "@material-ui/core/Link"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import AboutMe from "../about"

const FooterContainer = styled.div`
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.02);
`
const FooterLink = styled(Link)`
  color: black;
  &:hover {
    color: black;
  }
`
const LinksBox = styled.div`
  display: flex;
  justify-content: center;
  & ${FooterLink} {
    margin: 0px 20px;
  }
`

const Footer = props => (
  <FooterContainer>
    <Container fixed>
      <AboutMe />
      <LinksBox>
        <FooterLink href="/politica-de-privacidad">
          <Typography>Política de privacidad</Typography>
        </FooterLink>
        <FooterLink href="/politica-de-cookies">
          <Typography>Política de cookies</Typography>
        </FooterLink>
      </LinksBox>
    </Container>
  </FooterContainer>
)

export default Footer
