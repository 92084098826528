import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
import Link from "@material-ui/core/Link"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.primary,
    fontWeight: "bold",
    flexGrow: 1,
  },
}))

const Header = () => {
  const classes = useStyles()

  return (
    <AppBar color="white" position="static" elevation={1}>
      <Toolbar>
        <Link variant="h6" className={classes.title} href="/">
          Into #DeFi
        </Link>
        <Link href="/">
          <Button color="primary">Recursos</Button>
        </Link>
      </Toolbar>
    </AppBar>
  )
}

export default Header
